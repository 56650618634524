function Contact() {
    return (
      <section className="p-8 bg-gray-100 text-center">
        <h2 className="text-3xl font-bold mb-8">Get in Touch</h2>
        <form className="max-w-md mx-auto">
          <input className="w-full p-4 mb-4 border rounded" type="text" placeholder="Your Name" />
          <input className="w-full p-4 mb-4 border rounded" type="email" placeholder="Your Email" />
          <textarea className="w-full p-4 mb-4 border rounded" placeholder="Your Message" rows="4"></textarea>
          <button className="w-full p-4 bg-blue-500 text-white rounded hover:bg-blue-600">Send Message</button>
        </form>
      </section>
    );
  }

  export default Contact;
  