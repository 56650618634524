import { motion } from 'framer-motion';
import { FaAws, FaJava, FaReact, FaCode, FaLock, FaDatabase, FaChalkboardTeacher } from 'react-icons/fa';
import { SiSpringboot, SiAmazon, SiGithub } from 'react-icons/si';
import { useState } from 'react';

const techSkills = [
  { name: "System Design", description: "Designing highly available, fault-tolerant distributed systems.", icon: FaDatabase },
  { name: "Coding", description: "Writing high-quality, scalable code across Java, Spring Boot, Node.js.", icon: FaCode },
  { name: "Mentorship/Reviews", description: "Code reviews, design reviews, security reviews, and operational excellence.", icon: FaChalkboardTeacher },
  { name: "Security", description: "Ensuring best practices in security, including compliance and auditing.", icon: FaLock },
  // Add more skills as needed...
];

const projects = [
  { name: "CloudFormation", company: "AWS", logo: FaAws, description: "Built CloudFormation for infrastructure as code." },
  { name: "Amazon Pay & Alexa", company: "Amazon", logo: SiAmazon, description: "Integrated Amazon Pay with Alexa for voice-activated payments." },
  { name: "Anomaly Detection", company: "Amazon", logo: SiAmazon, description: "Developed real-time anomaly detection for Amazon's Just Walk Out stores." },
  // Add more projects as needed...
];

function Hero() {
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);

  return (
    <section className="relative h-[85vh] flex flex-col items-center justify-center bg-gradient-to-b from-black to-gray-900 text-white">
      {/* Subtle abstract background elements */}
      <div className="absolute top-0 left-0 w-20 h-20 bg-gradient-to-br from-gray-700 to-black opacity-50 rounded-full blur-xl animate-pulse"></div>
      <div className="absolute bottom-0 right-0 w-28 h-28 bg-gradient-to-br from-gray-700 to-black opacity-50 rounded-full blur-xl animate-pulse"></div>

      {/* Left Sidebar: Tech Skills (Hidden on mobile) */}
      <div className="hidden lg:flex absolute left-8 top-1/2 transform -translate-y-1/2 flex-col space-y-6">
        {techSkills.map((skill, index) => (
          <motion.div
            key={index}
            className="flex items-center space-x-3 bg-gray-800 p-4 rounded-lg shadow-lg hover:bg-gray-700 cursor-pointer"
            onClick={() => setSelectedSkill(skill)}
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.3 + index * 0.2, duration: 0.8 }}
          >
            <skill.icon size={32} className="text-indigo-400" />
            <p>{skill.name}</p>
          </motion.div>
        ))}
      </div>

      {/* Right Sidebar: Projects (Hidden on mobile) */}
      <div className="hidden lg:flex absolute right-8 top-1/2 transform -translate-y-1/2 flex-col space-y-6">
        {projects.map((project, index) => (
          <motion.div
            key={index}
            className="flex items-center space-x-3 bg-gray-800 p-4 rounded-lg shadow-lg hover:bg-gray-700 cursor-pointer"
            onClick={() => setSelectedProject(project)}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.3 + index * 0.2, duration: 0.8 }}
          >
            <project.logo size={32} className="text-yellow-400" />
            <p>{project.name}</p>
          </motion.div>
        ))}
      </div>

      {/* Main Content */}
      <motion.h1
        className="text-5xl font-extrabold mb-4 text-center tracking-tight"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: 'easeOut' }}
      >
        Hi, I'm Ashwani.
      </motion.h1>

      <motion.p
        className="text-lg mb-4 text-center max-w-md font-light tracking-wider text-gray-300"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.8 }}
      >
        Full Stack Engineer | Tech Founder | Innovator
      </motion.p>

      <motion.p
        className="text-md text-center max-w-md mb-6 text-gray-400"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.4, duration: 0.8 }}
      >
        Architected and developed large-scale enterprise solutions at Amazon and built AI-powered systems using Java Spring Boot, AWS, and React.
      </motion.p>

      {/* Call to Action Buttons */}
      <motion.div
        className="flex space-x-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.6, duration: 0.8 }}
      >
        <a
          href="#projects"
          className="px-6 py-2 bg-gradient-to-r from-indigo-500 to-pink-500 text-white rounded-full shadow-lg hover:shadow-xl hover:from-indigo-600 hover:to-pink-600 transition-all"
        >
          View My Work
        </a>
        <a
          href="#contact"
          className="px-6 py-2 bg-gray-800 text-white rounded-full shadow-lg hover:bg-gray-700 hover:shadow-xl transition-all"
        >
          Contact Me
        </a>
      </motion.div>

      {/* Scroll Indicator */}
      <div className="absolute bottom-4">
        <a href="#work-experience" className="animate-bounce text-gray-400">
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        </a>
      </div>

      {/* Modal for Selected Skill */}
      {selectedSkill && (
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          onClick={() => setSelectedSkill(null)}
        >
          <div className="bg-white p-6 rounded-lg text-black max-w-md w-full">
            <h2 className="text-2xl font-bold mb-4">{selectedSkill.name}</h2>
            <p>{selectedSkill.description}</p>
          </div>
        </motion.div>
      )}

      {/* Modal for Selected Project */}
      {selectedProject && (
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          onClick={() => setSelectedProject(null)}
        >
          <div className="bg-white p-6 rounded-lg text-black max-w-md w-full">
            <h2 className="text-2xl font-bold mb-4">{selectedProject.name}</h2>
            <p>{selectedProject.description}</p>
          </div>
        </motion.div>
      )}
    </section>
  );
}

export default Hero;
