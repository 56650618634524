import React from 'react';

function Projects() {
    const projects = [
      { 
        title: 'Real-Time Anomaly Detection', 
        description: 'Built for Amazon Just Walk Out stores using AI to detect and resolve operational issues in real time.', 
        techStack: ['AWS', 'Python', 'AI'], 
        role: 'Lead Developer',
      },
      { 
        title: 'Alexa Payment Integration', 
        description: 'Integrated Amazon Pay with Alexa for seamless voice-enabled payments, enhancing user experience.', 
        techStack: ['Java', 'Spring Boot', 'AWS Lambda'], 
        role: 'Software Engineer',
      },
    ];
  
    return (
      <section className="p-16 bg-gray-900 text-white">
        <h2 className="text-4xl font-bold text-center mb-12">Key Projects</h2>
        
        {/* Projects Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {projects.map((project, index) => (
            <div
              key={index}
              className="relative rounded-lg overflow-hidden shadow-lg bg-gray-800 p-6"
            >
              {/* Project Content */}
              <div className="space-y-4">
                <h3 className="text-2xl font-bold">{project.title}</h3>
                <p className="text-gray-300">{project.description}</p>
  
                {/* Tech Stack */}
                <div className="flex flex-wrap space-x-3">
                  {project.techStack.map((tech, i) => (
                    <span key={i} className="px-3 py-1 bg-indigo-600 rounded-full text-sm">
                      {tech}
                    </span>
                  ))}
                </div>
  
                {/* Role */}
                <p className="text-sm text-gray-400 italic">Role: {project.role}</p>
  
                {/* Learn More Button */}
                <button className="mt-4 px-4 py-2 bg-white text-black font-semibold rounded-lg">
                  Learn More
                </button>
              </div>
            </div>
          ))}
        </div>
      </section>
    );
  }
  
  export default Projects;
  
