import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Projects from './components/Projects';
import ProjectDetail from './components/ProjectDetail';
import Contact from './components/Contact';
import Footer from './components/Footer';
// import ScrollToTop from './components/ScrollToTop';
// import Loading from './components/Loading';

// Lazy loading the pages
// const Home = lazy(() => import('./pages/Home'));
// const Projects = lazy(() => import('./components/Projects'));
// const ProjectDetail = lazy(() => import('./components/ProjectDetail'));
// const Contact = lazy(() => import('./components/Contact'));
// const NotFound = lazy(() => import('./components/NotFound'));

function App() {
  return (
    <Router>
      <Navbar />
      {/* <ScrollToTop /> */}
      {/* <Suspense fallback={<Loading />}> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/:id" element={<ProjectDetail />} />
          <Route path="/contact" element={<Contact />} />
          {/* <Route path="*" element={<NotFound />} /> 404 Page */}
        </Routes>
      {/* </Suspense> */}

      <Footer/>
    </Router>
  );
}

export default App;
