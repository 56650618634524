import React from 'react';
import { FaAws, FaAmazon, FaRobot, FaDatabase, FaJava, FaReact } from 'react-icons/fa';
import { SiSpringboot, SiAwsamplify } from 'react-icons/si';
import { GiNetworkBars } from 'react-icons/gi';
import { motion } from 'framer-motion';
import lambdaIcon from '../assets/Architecture-Service-Icons_06072024/Arch_Compute/16/Arch_AWS-Lambda_16.png';
import sqsIcon from '../assets/Architecture-Service-Icons_06072024/Arch_App-Integration/16/Arch_Amazon-Simple-Queue-Service_16.png';
import snsIcon from '../assets/Architecture-Service-Icons_06072024/Arch_App-Integration/16/Arch_Amazon-Simple-Notification-Service_16.png';

// Amazon experience data
const amazonExperience = [
  {
    team: "AWS CloudFormation",
    duration: "2019 - 2021",
    projects: [
      {
        title: "AWS CloudFormation Engineer",
        description: "Contributed to AWS CloudFormation, enhancing features for seamless infrastructure as code and improving resource provisioning at scale.",
        icon: FaAws,
        color: "bg-blue-500",
        techStack: [
          { icon: FaJava, label: "Java" },
          { icon: SiSpringboot, label: "Spring Boot" },
          { icon: FaAws, label: "AWS" }
        ],
      },
    ],
  },
  {
    team: "Amazon Pay",
    duration: "2021 - 2022",
    projects: [
      {
        title: "Amazon Pay Integration with Alexa",
        description: "Integrated Amazon Pay with Alexa, enabling voice-activated payments through secure and user-friendly workflows.",
        icon: FaAmazon,
        color: "bg-orange-500",
        techStack: [
          { icon: FaJava, label: "Java" },
          { icon: SiSpringboot, label: "Spring Boot" },
          { icon: FaAws, label: "AWS" },
          { icon: FaReact, label: "React" },
        ],
      },
    ],
  },
  {
    team: "Amazon Just Walk Out",
    duration: "2022 - 2024",
    projects: [
      {
        title: "Real-Time Anomaly Detection System",
        description: "Developed a real-time anomaly detection system leveraging AI and sensor data to improve operational issue identification in Amazon's Just Walk Out stores.",
        icon: FaRobot,
        color: "bg-green-500",
        techStack: [
            { icon: FaJava, label: "Java" },
            { icon: SiSpringboot, label: "Spring Boot" },
            { icon: FaAws, label: "AWS" },
            { icon: GiNetworkBars, label: "API Gateway" },
            { icon: lambdaIcon, label: "AWS Lambda", isImage: true },
            { icon: sqsIcon, label: "SQS", isImage: true },
            { icon: snsIcon, label: "SNS", isImage: true },
        ]
      },
      {
        title: "Just Walk Out RAG Bot",
        description: "Built an AI-powered bot that reads internal documents, answers company queries, and provides new users with information about SOPs and processes.",
        icon: FaRobot,
        color: "bg-green-500",
        techStack: [
          { icon: FaJava, label: "Java" },
          { icon: SiSpringboot, label: "Spring Boot" },
          { icon: FaAws, label: "AWS" },
        ],
      },
      {
        title: "AI-Powered Analytics System",
        description: "Developed an AI-powered analytics system using OpenSearch and vector databases, providing product managers with real-time insights.",
        icon: FaDatabase,
        color: "bg-purple-500",
        techStack: [
          { icon: FaJava, label: "Java" },
          { icon: SiSpringboot, label: "Spring Boot" },
          { icon: FaAws, label: "AWS" },
        ],
      },
    ],
  },
];

// Tech stack icon rendering helper
const renderTechStack = (techStack) => {
  return techStack.map((tech, index) => (
    <div key={index} className="flex items-center space-x-2">
      {tech.isImage ? (
        <img src={tech.icon} alt={tech.label} title={tech.label} className="w-6 h-6" />
      ) : (
        <tech.icon size={24} className="text-indigo-600" />
      )}
      <span className="text-gray-300">{tech.label}</span>
    </div>
  ));
};

function WorkExperience() {
  return (
    <section className="py-16 bg-black">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-center text-white mb-12">Work Experience</h2>

        {/* Amazon Work Experience */}
        <div className="mb-12">
          <h3 className="text-3xl font-bold text-gray-300 mb-6">Software Development at Amazon (2019 - 2024)</h3>
          
          {/* Loop through Amazon teams */}
          {amazonExperience.map((team, teamIndex) => (
            <div key={teamIndex} className="mb-8">
              <h4 className="text-2xl font-semibold text-gray-400 mb-4">{team.team} ({team.duration})</h4>
              <div className="space-y-6">
                {team.projects.map((project, projectIndex) => (
                  <motion.div
                    key={projectIndex}
                    className="flex flex-col items-start space-y-4 bg-gray-900 p-6 rounded-lg shadow-lg hover:shadow-xl transition-all"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: projectIndex * 0.2 }}
                  >
                    {/* Icon Section */}
                    <div className="flex items-center space-x-4">
                      <div className={`flex-shrink-0 p-4 rounded-full ${project.color} text-white`}>
                        <project.icon size={32} />
                      </div>

                      {/* Content Section */}
                      <div>
                        <h5 className="text-xl font-bold text-white mb-2">{project.title}</h5>
                        <p className="text-gray-400">{project.description}</p>
                      </div>
                    </div>

                    {/* Tech Stack Section */}
                    <div className="flex flex-wrap items-center space-x-4 mt-4">
                      <span className="text-gray-400 font-semibold">Tech Stack:</span>
                      {renderTechStack(project.techStack)}
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default WorkExperience;
