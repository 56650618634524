import React from 'react';
import { FaAws, FaReact, FaNodeJs, FaDatabase, FaPython, FaServer, FaCloud } from 'react-icons/fa';
import { SiOpensearch, SiMongodb, SiPostgresql, SiJavascript, SiDocker } from 'react-icons/si';

const frontend = [
  { name: 'React', icon: FaReact },
  { name: 'JavaScript', icon: SiJavascript },
  { name: 'CSS3', icon: FaReact }, // Add a correct CSS icon
  { name: 'HTML5', icon: FaReact }, // Add a correct HTML icon
  { name: 'Bootstrap', icon: FaReact }, // Add a Bootstrap icon
];

const backend = [
  { name: 'Node.js', icon: FaNodeJs },
  { name: 'Express.js', icon: FaServer },
  { name: 'Python', icon: FaPython },
  { name: 'Django', icon: FaServer }, // Add a Django icon
  { name: 'Spring Boot', icon: FaServer }, // Add Spring Boot icon
];

const cloud = [
  { name: 'AWS', icon: FaAws },
  { name: 'OpenSearch', icon: SiOpensearch },
  { name: 'SQS', icon: FaCloud },
  { name: 'Docker', icon: SiDocker },
  { name: 'Kubernetes', icon: FaServer }, // Add a Kubernetes icon
];

const databases = [
  { name: 'MongoDB', icon: SiMongodb },
  { name: 'DynamoDB', icon: FaDatabase },
  { name: 'PostgreSQL', icon: SiPostgresql },
  { name: 'Redis', icon: FaServer }, // Add Redis icon
  { name: 'MySQL', icon: FaServer }, // Add MySQL icon
];

function TechStack() {
  return (
    <section className="p-16 bg-black text-center">
      <h2 className="text-5xl font-extrabold text-white mb-16 tracking-tight">Tech Stack</h2>

      {/* Frontend Section */}
      <div className="mb-12">
        <h3 className="text-3xl font-semibold mb-8 text-white">Frontend Technologies</h3>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-12 justify-items-center">
          {frontend.map((tech, index) => (
            <div
              key={index}
              className="group transform hover:scale-105 transition-transform bg-gray-900 hover:bg-gray-700 p-6 rounded-lg shadow-lg hover:shadow-xl"
            >
              <tech.icon size={48} className="text-white group-hover:text-gray-400 transition-colors duration-300" />
              <p className="mt-4 text-lg font-medium text-white group-hover:text-gray-300">{tech.name}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Backend Section */}
      <div className="mb-12">
        <h3 className="text-3xl font-semibold mb-8 text-white">Backend Technologies</h3>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-12 justify-items-center">
          {backend.map((tech, index) => (
            <div
              key={index}
              className="group transform hover:scale-105 transition-transform bg-gray-900 hover:bg-gray-700 p-6 rounded-lg shadow-lg hover:shadow-xl"
            >
              <tech.icon size={48} className="text-white group-hover:text-gray-400 transition-colors duration-300" />
              <p className="mt-4 text-lg font-medium text-white group-hover:text-gray-300">{tech.name}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Cloud Section */}
      <div className="mb-12">
        <h3 className="text-3xl font-semibold mb-8 text-white">Cloud Technologies</h3>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-12 justify-items-center">
          {cloud.map((tech, index) => (
            <div
              key={index}
              className="group transform hover:scale-105 transition-transform bg-gray-900 hover:bg-gray-700 p-6 rounded-lg shadow-lg hover:shadow-xl"
            >
              <tech.icon size={48} className="text-white group-hover:text-gray-400 transition-colors duration-300" />
              <p className="mt-4 text-lg font-medium text-white group-hover:text-gray-300">{tech.name}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Database Section */}
      <div className="mb-12">
        <h3 className="text-3xl font-semibold mb-8 text-white">Database Technologies</h3>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-12 justify-items-center">
          {databases.map((tech, index) => (
            <div
              key={index}
              className="group transform hover:scale-105 transition-transform bg-gray-900 hover:bg-gray-700 p-6 rounded-lg shadow-lg hover:shadow-xl"
            >
              <tech.icon size={48} className="text-white group-hover:text-gray-400 transition-colors duration-300" />
              <p className="mt-4 text-lg font-medium text-white group-hover:text-gray-300">{tech.name}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default TechStack;
