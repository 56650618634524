import { Helmet } from 'react-helmet';
import Hero from '../components/Hero';
import Skills from '../components/Skills';
import Projects from '../components/Projects';
import WorkExperience from '../components/WorkExperience';
import TechStack from '../components/TechStack';


function Home() {
  return (
    <>
      <Helmet>
        <title>Your Name | Portfolio</title>
        <meta name="description" content="Portfolio showcasing my work as a tech founder." />
      </Helmet>
      <Hero />
      <WorkExperience />
      <TechStack />
      <Projects /> {/* Can be a preview of top projects */}
    </>
  );
}

export default Home;
