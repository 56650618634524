import { useParams } from 'react-router-dom';

function ProjectDetail() {
  const { id } = useParams();
  // Fetch project details using id or use static data for now

  return (
    <section className="p-8 bg-white">
      <h1 className="text-4xl">Project {id}</h1>
      <p>Details about project {id}...</p>
      <a href="https://github.com/yourproject" className="text-blue-500">View on GitHub</a>
    </section>
  );
}

export default ProjectDetail;
